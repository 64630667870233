@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#mint-medical-store-root {
  height: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px white;
}

.quill > .ql-container.ql-disabled {
  border: none;
  border-style: none;
}

.quill > .ql-container a {
  color: '#0AA078 !important';
}
